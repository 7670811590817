import React from 'react';
import { Item } from '../../items/item';
import cmsUtils from '../../utils/cmsUtils';
import  utils from '../../../utils';
import Slider from "react-slick";

export function BannerPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems);

  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';

  const slides = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
    autoplay: true,
    useCSS: true,
    useTransform: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const cssClass = utils.classNames('cms_item', 'bannerPanel', `bannerWrapperSize--${heightSet}`, item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <Slider {...settings} >
        {slides}
      </Slider>
    </div>
  );
}