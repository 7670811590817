const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorGold : '#c5a47e',
  colorDarkGray : '#252525'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"jaf-bernino-sans-comp",serif',

  primaryColor: colors.colorGold,
  fontColor: '#ebebeb',
  bgColor: 'BLACK',

  ...colors,
  ...overloading
}

export default variables;